<script setup lang="ts">
import Generic from '~/layouts/generic.vue'
import Header from '~/components/Layout/Header.vue'
import LazyNewsletterDialog from '~/components/Newsletter/NewsletterDialog.vue';

const { setCategory } = useCategoryStore()

useAsyncData('category', async () => setCategory())
</script>

<template>
  <Generic>
    <Header alternative />
    <LazyNewsletterDialog />
    <Toast position="bottom-center" group="bl" />
    <div class="mt-20 w-full bg-white md:mt-28">
      <router-view />
    </div>
  </Generic>
</template>
